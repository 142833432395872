import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

const theme = {
  red: "#3023AE",
  black: "#393939",
  grey: "#3A3A3A",
  lightgrey: "#E1E1E1",
  offWhite: "#EDEDED",
  maxWidth: "1000px",
  UI_Background: "#ececec",
  UI_PRIMARY: "rgba(48, 35, 174, 1)",
  UI_SECONDARY: "rgba(255, 103, 0, 1)",
  color3: "rgba(91, 192, 235, 1)",
  yellow: "rgba(253, 231, 76, 1)",
  yellowLight: "rgba(253, 231, 76, .5)",
  green: "rgba(155, 197, 61, 1)",
  greenLight: "rgba(155, 197, 61, .5)",
  border_radius: "20px",
  FONT_SIZE_SUB: "12px",
  FONT_SIZE_BODY: "16px",
  FONT_SIZE_DISPLAY: "22px",
  FONT_SIZE_HEADING: "40px",
  COLOUR_BLACK: "#262E33",
  COLOUR_LIGHT_BLUE: "#65C9FF",
  KEYLINE_STYLE: "1px solid #E1E1E1",
  STUDENTS_HIGHLIGHT: "rgb(255, 81, 80)",
  STUDENTS_BAR: "rgb(255, 229, 235)",
  LESSONS_HIGHLIGHT: "rgb(88, 152, 239)",
  LESSONS_BAR: "rgb(189, 231, 250)",
  CHALLENGES_HIGHLIGHT: "rgb(255, 218, 36)",
  CHALLENGES_BAR: "rgb(250, 246, 189)",
};

//

const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css?family=Nunito:200,400,900&display=swap');


  html {
    box-sizing: border-box;
    font-size: 10px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

 

h1 {
    margin-bottom : 5px;
    margin-top : 12px;
}

  h2 {
    font-weight : 200;
  }

  p {
    margin-bottom : 5px;
    margin-top : 12px;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-size : 16px;
    line-height: 1.6;
    font-family: 'Nunito Sans', sans-serif;
    background-color : rgb(250, 250, 250);
  }
  a {
    color: ${(props) => props.theme.black};
  }

  .hideLink {
    text-decoration : none;
  }

  button {  font-family: 'Nunito', Helvetica, Arial, sans-serif; }
  
  select {
    font-size : 16px;
  }



  
span.data-grid-container, span.data-grid-container:focus {
  outline: none;
}

.data-grid-container .data-grid {
  table-layout: fixed;
  border-collapse: collapse;
  width : 100%;
} 



.data-grid-container .data-grid .cell.updated {
    background-color: rgba(0, 145, 253, 0.16);
    transition : background-color 0ms ease ;
}
.data-grid-container .data-grid .cell {
  height: 17px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  transition : background-color 500ms ease;
  vertical-align: middle;
  text-align: left;
  border: 1px solid #DDD;
  padding: 0;
  background-color : #FFF;
  
}
.data-grid-container .data-grid .cell.selected {
  border: 1px double rgb(33, 133, 208);
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
}

.data-grid-container .data-grid .cell.read-only {
  background: whitesmoke;
  color: #000;
  text-align: left;
  padding-left : 15px;
  padding-left : 15px;
}

.data-grid-container .data-grid .cell > .text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}


.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align:right;
  width: 100%;
  height: 100%;
  background: none;
  display: block;
  font-size: 16px;
}


.data-grid-container .data-grid .cell {
  vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
  white-space: nowrap;
  overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
  white-space: nowrap;
  overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
  display: block;
}


`;

export { theme, GlobalStyle };
