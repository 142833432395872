import App from "next/app";
import Page from "../components/Page";
import { ApolloProvider } from "react-apollo";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import { getDataFromTree } from "@apollo/react-ssr";
import withApollo from "next-with-apollo";
import * as Sentry from "@sentry/node";
import { SessionProvider } from "next-auth/react";

Sentry.init({
  dsn: "https://1989b4281a6c408e89f9486c77093cc5@sentry.io/1418650",
  environment: process.env.SENTRY_ENV,
  enabled: process.env.NODE_ENV === "production",
});

class MyApp extends App {
  render() {
    const { Component, pageProps, apollo, session } = this.props;

    const { err } = this.props;

    const modifiedPageProps = { ...pageProps, err };

    return (
      <ApolloProvider client={apollo}>
        <Page>
          <SessionProvider session={session}>
            <Component {...modifiedPageProps} />
          </SessionProvider>
        </Page>
      </ApolloProvider>
    );
  }
}

const ApolloPage = withApollo(
  ({ initialState, headers }) =>
    new ApolloClient({
      uri: process.env.APOLLO_SERVER_URL,
      credentials: "include",
      request: (operation) => {
        operation.setContext({
          fetchOptions: {
            credentials: "include",
          },
          headers: {
            cookie: headers && headers.cookie, // NOTE: client-side headers is undefined!
          },
        });
      },

      cache: new InMemoryCache().restore(initialState || {}),
    }),
  { getDataFromTree }
)(MyApp);

export default ApolloPage;
